// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import AltUjs from "alt-ujs"
AltUjs.start()

//import * as ActiveStorage from "@rails/activestorage"
//ActiveStorage.start()

//import "channels"

// bootstrap
import "bootstrap";

// fortawesome
import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";

// special entry point of application.css
import "@css/application"
